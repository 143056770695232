<template>
  <div class="property-dashboard-side-nav d-none d-lg-block">
    <div class="subtitle">ADVERTISING</div>
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'campaigns',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Ads Manager
    </router-link>
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'report',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Ads Reporting
    </router-link>
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'audiences',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Ads Audiences
    </router-link>

    <div class="subtitle">PRODUCT</div>
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: '3D_walkthru',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      3D Walkthru
    </router-link>
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'video',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Video
    </router-link>
    <!-- <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'studio',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Studio
    </router-link> -->
    <router-link
      class="btn btn-info me-3"
      aria-current="page"
      :to="{
        name: 'contents',
        params: {
          propertyId: propertyId,
        },
      }"
    >
      Content
    </router-link>
  </div>
  <div
    class="offcanvas offcanvas-start d-block d-lg-none"
    tabindex="-1"
    id="offcanvasMenu"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="property-dashboard-side-nav">
        <div class="subtitle">ADVERTISING</div>
        <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: 'campaigns',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Ads Manager
        </router-link>
        <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: 'report',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Ads Reporting
        </router-link>
        <router-link
          class="btn btn-info outer-btn"
          aria-current="page"
          :to="{
            name: 'audiences',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Ads Audiences
        </router-link>

        <div class="subtitle">PRODUCT</div>
        <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: '3D_walkthru',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          3D Walkthru
        </router-link>
        <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: 'video',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Video
        </router-link>
        <!-- <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: 'studio',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Studio
        </router-link> -->
        <router-link
          class="btn btn-info router-btn"
          aria-current="page"
          :to="{
            name: 'contents',
            params: {
              propertyId: propertyId,
            },
          }"
        >
          Content
        </router-link>
      </div>
    </div>
  </div>
</template>
<style lang="sass">
@import "@/assets/vars.sass"
.property-dashboard-side-nav
  width: 200px
  margin-right: 30px
  margin-left: 1em
  margin-top: 2em
  +laptop
    width: 150px
    margin-right: 20px
  .subtitle
    color: $LightGrey
    font-weight: 600
    font-size: 12px
    line-height: 15px
    padding: 16px 18px
  a
    text-align: left
    padding: 16px 18px
    background-color: transparent
    border: none
    display: block
    font-weight: 600
    font-size: 15px
    line-height: 19px
    &:hover
      background-color: $LighterGrey
    &.router-link-active
      background-color: $LighterGrey
      pointer-events: none
    +mobile 
      font-size: 14px
  a.btn:active
    background-color: $LighterGrey
#offcanvasMenu
  width: 60%
</style>

<script>
export default {
  name: "PropertyDashboardSidebarNav",
  setup(props) {},
  props: ["propertyId"],
  computed: {},
  methods: {},
  mounted() {
    var routerBtns = document.querySelectorAll(".router-btn");
    routerBtns.forEach((routerBtn) => {
      routerBtn.addEventListener("click", function (event) {
        var body = document.body;
        body.style.removeProperty("overflow");
        body.style.removeProperty("padding-right");
      });
    });
  },
};
</script>
