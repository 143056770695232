import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CampaignView from '../views/CampaignView.vue'
// import FacebookAdsetTable from '../components/FacebookAdsetTable.vue'
// import FacebookAdTable from '../components/FacebookAdTable.vue'
import CampaignReportView from '../views/CampaignReportView.vue'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import SetPasswordView from '../views/SetPasswordView.vue'
import CreateUserView from '../views/CreateUserView.vue'
import WalkthruitCampaignFormView from '../views/WalkthruitCampaignFormView.vue'
import PublicWalkthruitCampaignFormView from '../views/PublicWalkthruitCampaignFormView.vue'
import PublicWalkthruitCampaignReportView from '../views/PublicWalkthruitCampaignReportView.vue'
import WalkthruitCampaignsView from '../views/WalkthruitCampaignsView.vue'
import PropertyContents from '../components/PropertyContents.vue'
import PropertyStudio from '../components/PropertyStudio.vue'
import PropertyVideo from '../components/PropertyVideo.vue'
import PropertyAudiences from "@/components/PropertyAudiences.vue";
import ThreeDWalkthru from "@/components/ThreeDWalkthru.vue";
import PropertyReporting from "@/components/PropertyReporting.vue";
import listProperties from "@/components/ListProperty.vue";
import ListPropertyAdmin from "@/components/ListPropertyAdmin.vue";
import ListCampaignsInfoAdmin from "@/components/ListCampaignsInfoAdmin.vue";
import TrendingDeliverablesAdmin from "@/components/TrendingDeliverablesAdmin.vue";


const router = createRouter({
  history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/client/:clientID',
      name: 'clientView',
      component: listProperties,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
      props: true
    },
    {
      path: '/properties',
      name: 'properties',
      component: ListPropertyAdmin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      path: '/campaignsInfo',
      name: 'campaignsInfo',
      component: ListCampaignsInfoAdmin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        requiresSuperAdmin: true,
      }
    },
    {
      path: '/trendingDeliverables',
      name: 'trendingDeliverables',
      component: TrendingDeliverablesAdmin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      path: '/campaigns/:propertyId',
      name: 'campaigns',
      component: WalkthruitCampaignsView,
      meta: {
        requiresAuth: true,
        root: "campaigns"
      },
      props: true
    },
    {
      path: '/new_campaign/:propertyId',
      name: 'new_campaign',
      component: WalkthruitCampaignFormView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        root: "campaigns"
      },
      props: true
    },
    {
      path: '/edit_campaign/:propertyId/:campaignId',
      name: 'edit_campaign',
      component: WalkthruitCampaignFormView,
      meta: {
        requiresAuth: true,
        root: "campaigns"
      },
      props: true
    },
    {
      path: '/show_campaign/:propertyId/:campaignId',
      name: 'show_campaign',
      component: WalkthruitCampaignFormView,
      meta: {
        requiresAuth: true,
        root: "campaigns"
      },
      props: true
    },
    {
      path: '/share_campaign',
      name: 'public_campaign',
      component: PublicWalkthruitCampaignFormView,
      meta: {
        root: "campaigns"
      },
    },
    {
      path: '/share_report',
      name: 'public_report',
      component: PublicWalkthruitCampaignReportView,
      meta: {
        root: "report"
      },
    },
    {
      path: '/contents/:propertyId',
      name: 'contents',
      component: PropertyContents,
      meta: {
        requiresAuth: true,
        root: "contents"
      },
      props: true
    },
    {
      path: '/reporting/:propertyId',
      name: 'report',
      component: PropertyReporting,
      meta: {
        requiresAuth: true,
        root: "report"
      },
      props: true
    },
    {
      path: '/studio/:propertyId',
      name: 'studio',
      component: PropertyStudio,
      meta: {
        requiresAuth: true,
        root: "studio"
      },
      props: true
    },
    {
      path: '/video/:propertyId',
      name: 'video',
      component: PropertyVideo,
      meta: {
        requiresAuth: true,
        root: "video"
      },
      props: true
    },
    {
      path: '/audiences/:propertyId',
      name: 'audiences',
      component: PropertyAudiences,
      meta: {
        requiresAuth: true,
        root: "audiences"
      },
      props: true
    },
    {
      path: '/platform_campaigns/:propertyId/:campaignId',
      name: 'platform_campaigns',
      component: CampaignView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        root: "campaigns"
      },
      props: true
    },
    {
      path: '/campaign_report/:propertyId/:campaignId',
      name: 'campaign_report',
      component: CampaignReportView,
      meta: {
        requiresAuth: true,
        root: "report"
      },
      props: true
    },
    {
      path: '/3D_walkthru/:propertyId',
      name: '3D_walkthru',
      component: ThreeDWalkthru,
      meta: {
        requiresAuth: true,
        root: "3D_walkthru"
      },
      props: true
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutView
    },
    {
      path: '/forgot-password',
      name: 'forget_password',
      component: ForgotPasswordView
    },
    {
      path: '/reset_password',
      name: 'reset_password',
      component: ResetPasswordView,
    },
    {
      path: '/set_password',
      name: 'set_password',
      component: SetPasswordView,
    },
    {
      path: '/create_user',
      name: 'create_user',
      component: CreateUserView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    // {
    //   path: '/404',
    //   name: 'Page_not_found',
    //   component: PageNotFound
    // },
    // { path: '/:pathMatch(.*)*', redirect: '/404' },
    // {
    //   path: '/facebook_adsets/:campaignId/:objective/:platform',
    //   name: 'facebook_adsets',
    //   component: FacebookAdsetTable,
    //   meta: {
    //     requiresAuth: true,
    //   },
    //   props: true
    // },
    // {
    //   path: '/facebook_ads/:adsetId/:platform',
    //   name: 'facebook_ads',
    //   component: FacebookAdTable,
    //   meta: {
    //     requiresAuth: true,
    //   },
    //   props: true
    // },
    // { path: '/facebook_ad_sets:id(.*)', component: FacebookAdsetTable },
    // { path: '/facebook_ads:id(.*)', component: FacebookAdTable }
  ]
})

export default router;