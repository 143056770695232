<template>
  <div
    class="resultprev ins-template"
    :class="{
      'mobile-preview': mobileView,
      'resultprev-carousel': adType == 3,
      'resultprev-video': adType == 1,
    }"
  >
    <div class="prev_head d-flex">
      <div class="logo">
        <img :src="logo.url" class="logo-img" v-if="logo" />
        <img src="@/assets/logo-sample.png" class="logo-img" v-else />
      </div>
      <div class="company-container">
        <span
          class="company"
          :class="{
            mobile_property_name: mobileView,
          }"
        >
          {{ propertyName ? propertyName : propertyNamePlaceholder }}
        </span>
        <span
          class="sponsored"
          :class="{
            mobile_sponsored: mobileView,
          }"
        >
          Sponsored
        </span>
      </div>
      <div class="ellipsis" :class="{ ellipsis_mobile: mobileView }">
        <font-awesome-icon icon="ellipsis" />
      </div>
    </div>
    <div
      class="prev_image m-0"
      :class="{
        'p-0': !mobileView,
      }"
    >
      <div class="ad-asset-container">
        <div v-if="adType == 1">
          <span v-if="adVideo == null" class="video-asset-nocontent"> </span>
          <span v-else>
            <video
              :src="adVideo.url"
              controls
              v-if="adVideo.type.includes('video')"
              class="ad-asset-video"
            ></video>
            <img
              :src="adVideo.url"
              class="ad-asset"
              v-if="adVideo.type.includes('image')"
            />
          </span>
        </div>
        <div
          v-if="
            sIAdTypes.includes(adType) || sIAdTypes.includes(adType.toString())
          "
        >
          <span v-if="adImage == null" class="ad-asset-nocontent"> </span>
          <span v-else>
            <img
              :src="adImage.url"
              class="ad-asset"
              v-if="adImage.type.includes('image')"
            />
          </span>
        </div>
        <div v-if="adType == 3" class="prev-carousel">
          <Carousel
            :adType="adType"
            :files="carouselFiles"
            :cardInfo="cardInfo"
            :ctaType="ctaType"
            :domain="domain"
            :mobileView="mobileView"
            :currentSlide="carouseSlides[mobileView ? 1 : 0]"
            :platform="5"
            :key="'ins' + componentKey"
          ></Carousel>
        </div>
      </div>
    </div>
    <div class="preview-below-image">
      <div class="cta">
        <span
          ><b>{{ ctaType ? ctaType : "Learn more" }}</b></span
        >
      </div>
      <span
        class="cta-icon"
        v-if="
          adType == 1 ||
          sIAdTypes.includes(adType) ||
          sIAdTypes.includes(adType.toString())
        "
      >
        <font-awesome-icon icon="angle-right" />
      </span>
      <div class="content">
        <div class="actions">
          <button class="btn btn-action btn-action-love">
            <img src="@/assets/Like.svg" class="w-100" />
          </button>
          <button class="btn btn-action btn-action-love">
            <img src="@/assets/Comment.svg" class="w-100" />
          </button>
          <button class="btn btn-action btn-action-love">
            <img src="@/assets/SharePost.svg" class="w-100" />
          </button>
          <button class="btn btn-action btn-action-bookmark">
            <img src="@/assets/Bookmark.svg" style="width: 24px" />
          </button>
        </div>
        <div class="views">
          <span class="view-text">89 likes</span>
        </div>
        <div class="link-primaryText">
          <span class="company">{{
            propertyName ? propertyName : propertyNamePlaceholder
          }}</span
          >&nbsp;
          <span class="description">{{
            headline ? headline : headlinePlaceholder
          }}</span>
        </div>
        <div class="comments">
          <span class="comments-text" data-v-fae5ef6a=""
            >View all 14 comments</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultprev.mobile-preview {
  border: 0;
  border-radius: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  flex-basis: 375px;
  margin: 0 auto;
}
.ins-template {
  --instagram-font-family-system: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  --instagram-font-weight-system-regular: 400;
  --instagram-font-weight-system-semibold: 600;
  --instagram-post-separator: 239, 239, 239;
  --instagram-primary-background: #fff;
  --instagram-primary-text: 38, 38, 38;
  --instagram-secondary-button: 38, 38, 38;
  --instagram-system-14-font-size: 14px;
  --instagram-system-14-line-height: 18px;
  font-family: -apple-system, system-ui, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 0;
}
.resultprev.resultprev-carousel.mobile-preview {
  max-width: 320px;
}
.resultprev.resultprev-carousel {
  max-width: 332px;
}
.resultprev {
  // max-width: 95%;
  margin: 0 auto;
  align-content: stretch;
  align-items: stretch;
  background-color: var(--instagram-primary-background);
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: var(--instagram-width);
  font-family: -apple-system, system-ui, Arial, sans-serif;
  .prev_head {
    align-content: stretch;
    align-items: center;
    background-color: var(--instagram-primary-background);
    border-bottom: 1px solid rgb(var(--instagram-post-separator));
    border-bottom-width: 0.5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 8px 40px 8px 12px;
    position: relative;
    .logo {
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      flex: none;
      flex-direction: row;
      height: 32px;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 32px;
      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
    .company-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      margin-left: 10px;
      margin-right: 4px;
      min-height: 40px;
      overflow: hidden;
      position: relative;
      .sponsored {
        color: #282828;
        font-size: 12px;
        line-height: 1rem;
        margin-top: -2px;
      }
    }
    .ellipsis {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: center;
      height: 60px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 12px;
      svg {
        fill: rgb(38, 38, 38);
        height: 16;
        width: 16;
      }
    }
    .ellipsis_mobile {
      transform: rotate(90deg);
    }
  }
  .prev_image {
    .ad-asset-container {
      background: #e1e1e1;
    }
    .video-asset-nocontent {
      display: inline-block;
    }
    .ad-asset-nocontent {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: inline-block;
    }
    img {
      width: 100%;
    }
    .ad-asset {
      width: 100%;
    }
    .ad-asset-video {
      width: 100%;
    }
  }
  .preview-below-image {
    align-content: stretch;
    align-items: stretch;
    background-color: rgb(var(--instagram-primary-background));
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    .cta {
      border-bottom: 1px solid #e9eaeb;
      color: rgb(var(--instagram-secondary-button));
      font-size: 14px;
      letter-spacing: normal;
      line-height: 16px;
      margin: 0 10px;
      padding: 10px 0 8px;
      position: relative;
      text-rendering: optimizelegibility;
      text-align: left;
    }
    .cta-icon {
      display: inline-block;
      height: 12px;
      position: absolute;
      top: 5px;
      right: 12px;
      width: 12px;
      svg {
        fill: rgb(var(--instagram-secondary-button));
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: 0 16px 16px;
      position: relative;
      min-width: var(--instagram-media-info);
      .actions {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        padding-bottom: 0;
        .btn-action {
          color: rgb(var(--instagram-primary-text));
          display: inline-flex;
          font-family: var(--instagram-font-family-system);
          font-size: var(--instagram-system-14-font-size);
          line-height: var(--instagram-system-14-line-height);
          margin-left: -8px;
          padding: 7px;
        }
        .btn-action.btn-action-bookmark {
          flex-grow: 1;
          justify-content: flex-end;
        }
      }
      .views .view-text {
        border: 1px solid transparent;
        font-weight: 700;
      }
      .link-primaryText {
        align-content: stretch;
        align-items: stretch;
        color: rgb(var(--instagram-primary-text));
        font-family: var(--instagram-font-family-system);
        font-size: var(--instagram-system-14-font-size);
        font-weight: var(--instagram-font-weight-system-regular);
        line-height: var(--instagram-system-14-line-height);
        justify-content: flex-start;
        min-height: 0;
        overflow: hidden;
        position: relative;
        .description {
          align-content: stretch;
          align-items: stretch;
          color: rgb(var(--instagram-primary-text));
          font-family: var(--instagram-font-family-system);
          font-size: var(--instagram-system-14-font-size);
          font-weight: var(--instagram-font-weight-system-regular);
          line-height: var(--instagram-system-14-line-height);
          justify-content: flex-start;
          min-height: 0;
          overflow: auto;
          position: relative;
          word-break: break-word;
          white-space: -moz-pre-wrap;
          white-space: pre-wrap;
        }
      }
      .comments-text {
        color: #666;
      }
    }
  }
}
.company {
  color: rgb(var(--instagram-secondary-button));
  cursor: pointer;
  display: inline-block;
  font-family: var(--instagram-font-family-system);
  font-size: 14px;
  font-weight: var(--instagram-font-weight-system-semibold);
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
}
</style>

<script>
// Tools
import { ref } from "vue";
import Carousel from "@/components/Carousel.vue";
import api from "@/services/BackendConnection";

export default {
  name: "InstagramPreviewResult",
  setup(props) {
    return {
      carouseSlides: ref({ 0: 0, 1: 0 }),
      urlPlaceholder: ref("Destination URL here"),
      sIAdTypes: ["2", "4", "5", "6", "7", "8"],
    };
  },
  props: [
    "logo",
    "propertyName",
    "propertyNamePlaceholder",
    "primaryText",
    "primaryTextPlaceholder",
    "headline",
    "headlinePlaceholder",
    "adType",
    "adImage",
    "adVideo",
    "files",
    "cardInfo",
    "ctaType",
    "websiteUrl",
    "mobileView",
    "componentKey",
  ],
  components: {
    Carousel,
  },
  computed: {
    domain() {
      var domain = "";
      if (this.websiteUrl) {
        domain = this.websiteUrl.split("//")[1];
      }
      return domain;
    },
    url() {
      var url = this.websiteUrl;
      if (url) {
        url.split("//")[1];
        if (url.length > 30) {
          url = url.slice(0, 30) + "...";
        }
      }
      return url;
    },
    carouselFiles() {
      var carouselFiles = [];
      var files = this.files;
      if (this.files.length === 0) {
        carouselFiles.push({ type: "sample" }, { type: "sample" });
      } else {
        carouselFiles = [...carouselFiles, ...files];
        if (this.files.length === 1) {
          carouselFiles.push({ type: "sample" });
        }
      }
      return carouselFiles;
    },
  },
  methods: {},
  watch: {},
};
</script>
