<template>
  <div
    class="resultprev"
    :class="{
      'mobile-preview': mobileView,
      'resultprev-carousel': adType == 3,
      'resultprev-video': adType == 1,
    }"
  >
    <div class="prev_head d-flex">
      <div class="logo">
        <img :src="logo.url" class="logo-img" v-if="logo" />
        <img src="@/assets/logo-sample.png" class="logo-img" v-else />
      </div>
      <div>
        <p
          class="p-0 property_name"
          :class="{
            mobile_property_name: mobileView,
          }"
        >
          {{ propertyName ? propertyName : propertyNamePlaceholder }}
        </p>
        <p
          class="sponsored"
          :class="{
            mobile_sponsored: mobileView,
          }"
        >
          Sponsored<span v-if="!mobileView">
            · <font-awesome-icon icon="earth-americas"
          /></span>
        </p>
      </div>
      <div class="ms-auto">
        <button type="button" class="btn ellipsis">
          <font-awesome-icon icon="ellipsis" />
        </button>
        <!-- <button type="button" class="btn" v-if="mobileView">
          <font-awesome-icon icon="xmark" />
        </button> -->
      </div>
    </div>
    <div class="prev_text">
      <p class="primary-text">
        {{ primaryText ? primaryText : primaryTextPlaceholder }}
      </p>
    </div>
    <div
      class="prev_image m-0"
      :class="{
        'p-0': !mobileView,
      }"
    >
      <div class="ad-asset-container">
        <div v-if="adType == 1" class="single-asset-container">
          <span v-if="adVideo == null" class="video-asset-nocontent"> </span>
          <span v-else>
            <video
              :src="adVideo.url"
              controls
              v-if="adVideo.type.includes('video')"
              class="ad-asset-video"
            ></video>
            <img
              :src="adVideo.url"
              class="ad-asset"
              v-if="adVideo.type.includes('image')"
            />
          </span>
        </div>
        <div
          v-if="
            sIAdTypes.includes(adType) || sIAdTypes.includes(adType.toString())
          "
          class="single-asset-container"
        >
          <span v-if="adImage == null" class="ad-asset-nocontent"> </span>
          <span v-else>
            <img
              :src="adImage.url"
              class="ad-asset"
              v-if="adImage.type.includes('image')"
            />
          </span>
        </div>
        <div
          v-if="adType == 3"
          class="prev-carousel"
          :class="{
            prev_image_mobile: mobileView,
          }"
        >
          <Carousel
            :adType="adType"
            :files="carouselFiles"
            :cardInfo="cardInfo"
            :ctaType="ctaType"
            :domain="websiteUrl"
            :mobileView="mobileView"
            :currentSlide="carouseSlides[mobileView ? 1 : 0]"
            :showCarouselText="showCarouselText"
            :platform="1"
            :key="'facebook' + componentKey"
          ></Carousel>
        </div>
      </div>
    </div>
    <!-- <div
      class="prev_info d-flex m-0"
      v-if="adType == 1 || sIAdTypes.includes(adType) || sIAdTypes.includes(adType.toString())"
    >
      <div
        :class="{
          prev_info_text_mobile: mobileView,
          prev_info_text: !mobileView,
        }"
      >
        <div class="prev_info_url">
          {{ url ? url : urlPlaceholder }}
        </div>
        <div>
          <span class="prev_info_headline overflow-hidden">{{
            headline ? headline : headlinePlaceholder
          }}</span>

        </div>
      </div>
      <div class="cta">
        <button type="button" class="btn p-0" v-if="ctaType !== 'No button'">
          <span class="cta-button">
            {{ ctaType ? ctaType : "Learn more" }}</span
          >
        </button>
      </div>
    </div> -->
    <div class="fb-preview-actions">
      <button class="fb-action fb-like">
        <i class="fb-action-icon">
          <img src="@/assets/thumbup.png" class="w-100" />
        </i>
        <span class="fb-action-text">Like</span>
      </button>
      <button class="fb-action fb-comment">
        <i class="fb-action-icon">
          <img src="@/assets/fb-comment.png" class="w-100" />
        </i>
        <span class="fb-action-text">Comment</span>
      </button>
      <button class="fb-action fb-share">
        <i class="fb-action-icon">
          <img src="@/assets/fb-share.png" class="w-100" />
        </i>
        <span class="fb-action-text">Share</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultprev.mobile-preview {
  border: 0;
  border-radius: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  margin: 0 auto;
}
.resultprev {
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 5px;
  transition: 0.5s;
  // max-width: 95%;
  color: #1c1e21;
  margin: 0 auto;
  font-family: -apple-system, system-ui, Arial, sans-serif;
  .prev_head {
    margin-bottom: 12px;
    padding: 12px 16px 0 12px;
    .logo {
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 40px;
      max-height: 100%;
      overflow: hidden;
      position: relative;
      width: 40px;
      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .property_name {
    font-size: 14px;
    width: 100%;
    font-weight: 600;
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    margin-top: 4px;
    margin-bottom: 5px;
  }
  .mobile_property_name {
    line-height: 1.3333;
    font-size: 15px;
    margin-bottom: 0;
    text-decoration: none;
    word-break: break-word;
  }
  .ellipsis {
    padding: 8px;
    font-size: 12px;
    color: #1c1e21;
    svg {
      color: #65676b;
      height: 20px;
      width: 20px;
    }
  }
  .sponsored {
    color: #65676b;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2308;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    text-decoration: none;
    word-break: break-word;
    align-items: center;
  }
  .mobile_sponsored {
    margin-top: 0;
  }
  .sponsored svg {
    margin-left: 5px;
  }
  .primary-text {
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    color: #050505;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    margin: -5px 0;
    overflow: hidden;
    padding: 4px 16px 16px 16px;
    word-break: break-word;
  }
  .ad-asset-container {
    background: white;
  }
  .single-asset-container {
    background: #e1e1e1;
  }
  .video-asset-nocontent {
    display: inline-block;
  }
  .ad-asset-nocontent {
    width: 100%;
    aspect-ratio: 1 / 1;
    display: inline-block;
  }
  .ad-asset {
    width: 100%;
  }
  .ad-asset-video {
    width: 100%;
  }
  .prev_image_mobile {
    padding-left: 10px;
  }
  .prev_info {
    align-items: center;
    background-color: #f0f2f5;
    border-bottom: 1px solid #dddfe2;
    cursor: pointer;
    margin: 0;
    padding: 12px 16px;
    position: relative;
    .prev_info_text_mobile {
      max-width: 250px;
      flex: 1;
      overflow: hidden;
      padding: 0 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .prev_info_text {
      flex: 1;
      overflow: hidden;
      padding: 0 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .prev_info_url {
      color: #65676b;
      display: inline-block;
      font-size: 13px;
      line-height: 1.2308;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      max-width: 100%;
    }
    .prev_info_headline {
      color: rgb(5, 5, 5);
      display: inline-block;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: normal;
      line-height: 1.1765;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      max-width: 100%;
    }
    .prev_info_description {
      color: #65676b;
      display: block;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3333;
      margin: -4px 0 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
    }
    .cta {
      margin-left: 6px;
      padding: 0 6px 0 16px;
    }
    .cta-button {
      align-items: center;
      background-color: #e4e6eb;
      border: 0;
      border-radius: 6px;
      color: var(--facebook-primary-color);
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-wrap: nowrap;
      font-size: 15px;
      font-weight: 600;
      height: 36px;
      justify-content: center;
      letter-spacing: normal;
      line-height: 19px;
      overflow-wrap: break-word;
      padding: 0 12px;
      position: relative;
      text-align: start;
      text-decoration: none;
      white-space: nowrap;
    }
  }
  .fb-preview-actions {
    align-items: center;
    color: #616770;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 13px;
    order: 1;
    padding: 6px 14px;
    .fb-action {
      align-items: center;
      border-radius: 5px;
      display: flex;
      flex: 1 100%;
      font-weight: bold;
      height: 32px;
      justify-content: center;
      padding: 0 2px;
      border: none;
      background: #fff;
    }
    .fb-action-icon {
      height: 18px;
      margin-right: 5px;
      width: 18px;
    }
    .fb-like .fb-action-icon {
      position: relative;
      top: -2px;
    }
    .fb-action-text {
      font-size: 13px;
      color: #616770;
      font-weight: 700;
    }
  }
}
@media (max-width: 768px) {
  .resultprev.mobile-preview {
    width: 90%;
  }
}
</style>

<script>
// Tools
import { ref } from "vue";
import Carousel from "@/components/Carousel.vue";

export default {
  name: "FacebookPreviewResult",
  setup(props) {
    return {
      carouseSlides: ref(0),
      urlPlaceholder: ref("Destination URL here"),
      descriptionPlaceholder: ref("Description text goes here"),
      sIAdTypes: ["2", "4", "5", "6", "7", "8"],
    };
  },
  props: [
    "logo",
    "propertyName",
    "propertyNamePlaceholder",
    "primaryText",
    "description",
    "headline",
    "headlinePlaceholder",
    "primaryTextPlaceholder",
    "adType",
    "adImage",
    "adVideo",
    "files",
    "cardInfo",
    "ctaType",
    "websiteUrl",
    "mobileView",
    "componentKey",
    "showCarouselText",
  ],
  components: {
    Carousel,
  },
  computed: {
    url() {
      var url = this.websiteUrl;
      if (url) {
        url.split("//")[1];
        if (url.length > 30) {
          url = url.slice(0, 30) + "...";
        }
      }
      return url;
    },
    carouselFiles() {
      var carouselFiles = [];
      var files = this.files;
      if (this.files.length === 0) {
        carouselFiles.push({ type: "sample" }, { type: "sample" });
      } else {
        carouselFiles = [...carouselFiles, ...files];
        if (this.files.length === 1) {
          carouselFiles.push({ type: "sample" });
        }
      }

      // if (this.logo) {
      //   carouselFiles.push(this.logo);
      // } else {
      //   carouselFiles.push({ type: "sample" });
      // }
      return carouselFiles;
    },
  },
  methods: {},
  watch: {},
};
</script>
