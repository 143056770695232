<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="container h-100 pt-4">
      <div
        class="property-dashboard-content-heading row justify-content-between"
      >
        <div class="heading-text col-12 col-md-3 mb-4">
          <div class="current-property">
            <button
              class="navbar-toggler ms-auto d-inline d-lg-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasMenu"
            >
              <font-awesome-icon icon="compass" />
            </button>
            {{ campaignName }}
          </div>
          <div class="current-route">Ad Reporting</div>
        </div>
        <div
          class="col-12 col-md-6 buttons d-flex justify-content-end"
          v-if="campaignName"
        >
          <button
            type="button"
            class="btn btn-primary wti-btn wti-btn-white share-button"
            data-bs-toggle="modal"
            data-bs-target="#shareReportURL"
          >
            Share URL
          </button>
          <button
            type="button"
            class="btn btn-primary wti-btn wti-btn-white share-button ms-4"
            @click="generatePDF"
            :disabled="!isPDFDownloadReady"
          >
            <span
              v-if="!isPDFDownloadReady"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ !isPDFDownloadReady ? "" : "Download PDF" }}
          </button>
        </div>
        <div class="col-12 col-md-3 date-range-picker position-relative">
          <loading
            v-model:active="campaignLoading"
            :is-full-page="false"
            loader="dots"
            :opacity="1"
          />
          <div v-if="!campaignLoading">
            <VueDatePicker
              v-model="dateRange"
              range
              multi-calendars
              :min-date="campaignStartDate"
              :max-date="yesterday"
              v-if="!isLoading"
              :enable-time-picker="false"
            >
              <template #left-sidebar>
                <div v-for="(val, key) in rangeTypes" :key="key">
                  <button
                    type="button"
                    class="btn"
                    :class="{ active: this.rangeType == key }"
                    @click="updateRange(key)"
                  >
                    {{ val }}
                  </button>
                </div>
              </template>
            </VueDatePicker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end" v-if="addToDropdown">
        <div class="">
          <select class="form-select campaign-select" v-model="vtsCampaign">
            <option
              v-for="(item, key) in vtsCampaigns"
              :key="key"
              :value="item._id"
            >
              {{ item.campaignName }}
            </option>
          </select>
        </div>
      </div>
      <CampaignReport
        v-if="dateRange && dateRange.length > 0"
        :propertyId="propertyId"
        :campaignId="activeCampaignId"
        :dateRange="dateRange"
        @updateIsLoading="updateIsLoading"
        @updateCampaignData="updateCampaignData"
        @updateChartData="updateChartData"
        @updateCompaniesData="updateCompaniesData"
        @updateWebsitesData="updateWebsitesData"
        @enablePDFDownload="enablePDFDownload"
        @updateLikesData="updateLikesData"
      />
    </div>
  </div>
  <Modal id="shareReportURL">
    <template v-slot:title>Shareable URL</template>
    <template v-slot:body>
      <p class="border section mt-2 text-break">{{ publicUrl }}</p>
      <button
        type="button"
        v-clipboard:copy="publicUrl"
        class="btn btn-dark wti-btn-black"
      >
        Copy
      </button>
    </template>
  </Modal>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
.buttons .btn.share-button
  line-height: 16px
  +mobile
    margin-bottom: 10px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import moment from "moment";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import CampaignReport from "@/components/CampaignReport.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "CampaignReportView",
  setup(props) {
    var yesterday = moment().subtract(1, "days").toDate();
    return {
      yesterday: yesterday,
      dateRange: ref([]),
      rangeType: ref("thisMonth"),
      rangeTypes: ref({
        yesterday: "Yesterday",
        last7: "Last 7 Days",
        last30: "Last 30 Days",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        all: "All",
      }),
      isLoading: ref(false),
      addToDropdown: ref(false),
      campaignName: ref(null),
      startDate: ref(null),
      endDate: ref(null),
      publicUrl: ref(null),
      campaignLoading: ref(false),
      // users: ref([]),
      campaignData: ref(null),
      campaignLikes: ref(null),
      chartData: ref(null),
      reachedCompanies: ref(null),
      topWebsites: ref(null),
      isPDFDownloadReady: ref(false),
      vtsCampaign: ref(null),
      vtsCampaigns: ref([]),
      activeCampaignId: ref(null),
    };
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
  },
  components: {
    PropertyDashboardSidebarNav,
    CampaignReport,
    Modal,
  },
  computed: {
    campaignStartDate() {
      if (this.startDate) {
        return this.startDate;
      } else {
        return moment("2022-10-01").utc().toDate();
      }
    },
  },
  methods: {
    getCampaignInfo() {
      var info = {
        campaignId: this.activeCampaignId,
      };
      var _this = this;
      _this.campaignLoading = true;
      api()
        .getCampaignName(info)
        .then((res) => {
          if (res.data) {
            _this.campaignName = res.data.campaignName;
            _this.startDate = res.data.startDate;
            _this.endDate = res.data.endDate;
            _this.addToDropdown = res.data.addToDropdown;
            if (_this.addToDropdown) {
              _this.getVtsCampaigns();
            }
            _this.publicUrl = res.data.publicUrl;
            var start = _this.startDate
              ? moment(_this.startDate).utc().format("YYYY-MM-DD HH:mm:ss")
              : moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss");
            var endDate = _this.endDate
              ? moment(_this.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
              : _this.yesterday;
            if (res.data.campaignId == "652d4ce096d5ae0503069180") {
              _this.dateRange = [
                moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss"),
                moment("2023-02-28").utc().format("YYYY-MM-DD HH:mm:ss"),
              ];
            } else {
              _this.dateRange = [start, endDate];
              if (moment(start).utc().isSameOrAfter(moment(endDate).utc())) {
                _this.dateRange = [start, start];
              }
            }
            // _this.users = res.data.users;
            _this.campaignLoading = false;
          }
        });
    },
    getVtsCampaigns() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      _this.campaignLoading = true;
      api()
        .getVtsCampaigns(info)
        .then((res) => {
          if (res.data) {
            _this.vtsCampaigns = res.data;
            _this.vtsCampaign = _this.activeCampaignId;
            _this.campaignLoading = false;
          }
        });
    },
    updateIsLoading(value) {
      this.isLoading = value;
    },
    updateCampaignData(value) {
      this.campaignData = value;
    },
    updateLikesData(value) {
      this.campaignLikes = value;
    },
    updateChartData(value) {
      this.chartData = value;
    },
    updateCompaniesData(value) {
      this.reachedCompanies = value;
    },
    updateWebsitesData(value) {
      this.topWebsites = value;
    },
    enablePDFDownload(value) {
      this.isPDFDownloadReady = value;
    },
    updateRange(type) {
      var yesterday = this.yesterday;
      this.rangeType = type;
      switch (type) {
        case "yesterday":
          this.dateRange = [yesterday, yesterday];
          break;
        case "last7":
          this.dateRange = [
            moment(yesterday).subtract(6, "d").toDate(),
            yesterday,
          ];
          break;
        case "last30":
          this.dateRange = [
            moment(yesterday).subtract(29, "d").toDate(),
            yesterday,
          ];
          break;
        case "thisMonth":
          this.dateRange = [
            moment(yesterday).startOf("month").toDate(),
            yesterday,
          ];
          break;
        case "lastMonth":
          this.dateRange = [
            moment(yesterday).subtract(1, "months").startOf("month"),
            moment(yesterday).subtract(1, "months").endOf("month"),
          ];
          break;
        case "all":
          var start = this.startDate
            ? moment(this.startDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss");
          var endDate = this.endDate
            ? moment(this.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : yesterday;
          this.dateRange = [start, endDate];
          break;
      }
    },
    generatePDF() {
      var campaignName = this.campaignName;
      var reportData = {
        campaignName: campaignName,
        dates:
          moment(this.dateRange[0]).utc().format("MM/DD/YYYY") +
          " - " +
          moment(this.dateRange[1]).utc().format("MM/DD/YYYY"),
        campaignData: this.campaignData,
        chartData: this.chartData,
        reachedCompanies: this.reachedCompanies,
        topWebsites: this.topWebsites,
        campaignLikes: this.campaignLikes,
      };
      var info = {
        reportData: reportData,
      };
      api()
        .generateReportPDF(info)
        .then((res) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", campaignName + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
  mounted() {
    this.activeCampaignId = this.campaignId;
    this.getCampaignInfo(this.activeCampaignId);
  },
  watch: {
    dateRange(val) {
      if (val && !val[1]) {
        this.dateRange[1] = this.dateRange[0];
      }
    },
    vtsCampaign(val) {
      if (val) {
        this.activeCampaignId = val;
        this.getCampaignInfo(val);
      }
    },
  },
};
</script>
